html,
body,
#__next {
  height: 100%;
  width: 100%;
}

/* disables touch menu */
* {
  -webkit-touch-callout: none !important;
}
