
/* Mouse Handle */

@media (hover: hover) {
  .react-flow__handle {
    width: 14px !important;
    height: 14px !important;
    border: 3px solid #fff !important;
  }
  .react-flow__handle-left {
    left: -6px !important;
  }
  .react-flow__handle-right {
    right: -6px !important;
  }
}

/* Touch Handle */

@media (hover: none) {
  .react-flow__handle {
    width: 18px !important;
    height: 18px !important;
    border: 4px solid #fff !important;
  }
  .react-flow__handle-left {
    left: -8px !important;
  }
  .react-flow__handle-right {
    right: -8px !important;
  }
}

/* Attribution */

.react-flow__attribution{ 
  display: none;
}